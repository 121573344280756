import React from "react";
import { AnimatePresence } from "framer-motion";

import GridRow from "../../commons/GridRow";
import Box from "../../commons/Box";
import RichTextRenderer from "../../commons/RichTextRenderer";

import { ReactComponent as SquadModel } from "../../../images/squad-model.svg";

import Card from "./Card";
import Heading from "../../commons/Heading";

export default function SquadModelBlock({ _rawTitle, list, ...otherProps }) {
  const svgRef = React.useRef();
  const [category, setCategory] = React.useState(list[0].label.toLowerCase());

  const selectedItem = list.find(
    (item) => item.label.toLowerCase() === category
  );

  const handleCategoryClick = React.useCallback((e) => {
    const activeEl = svgRef.current.querySelector(".active");
    if (activeEl) activeEl.classList.remove("active");
    e.currentTarget.classList.add("active");
    setCategory(e.currentTarget.dataset.category);
  }, []);

  React.useEffect(() => {
    const el = svgRef.current;

    const newActiveEl = el.querySelector(`[data-category="${category}"]`);
    if (newActiveEl) newActiveEl.classList.add("active");

    el.querySelectorAll(".category").forEach((category) =>
      category.addEventListener("click", handleCategoryClick)
    );

    return () => {
      el.querySelectorAll(".category").forEach((category) =>
        category.removeEventListener("click", handleCategoryClick)
      );
    };
  }, [handleCategoryClick, category]);

  return (
    <Box as="section">
      <GridRow
        sx={{
          position: "relative",
          gridAutoRows: "min-content",
          gridRowGap: 0,
        }}
      >
        <GridRow.Col
          ref={svgRef}
          gridColumn={["start / end", "start / span 7"]}
          sx={{
            gridRow: [2, "auto"],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            py: "gridGap",
            svg: {
              ".category": {
                "&:hover, &.active": {
                  ".border": {
                    opacity: 1,
                    visibility: "visible",
                  },
                  ".text > *": {
                    fill: "black",
                  },
                  ".bg > *": {
                    fill: "#ECEDF5",
                  },
                  ".bg2 > *": {
                    fill: "#BFC6DB",
                  },
                },
              },
              ".border": {
                opacity: 0,
                visibility: "hidden",
              },
              ".border, .text, .bg, .bg2": {
                transition: (theme) => `all ${theme.transitions.normal}`,
              },
            },
          }}
        >
          <Box sx={{ width: ["100%", "70%"], minWidth: 330 }}>
            <SquadModel />
          </Box>
        </GridRow.Col>
        <GridRow.Col
          gridColumn={["1 / -1", "9 / -1"]}
          sx={{
            textAlign: ["center", "start"],
            p: "gridGap",
            pt: "8vw",
            backgroundColor: "gray.0",
            minHeight: ["320px"],
            overflow: "hidden",
          }}
        >
          <RichTextRenderer blocks={_rawTitle} />
          <Box
            as="section"
            sx={{ position: "relative", mt: 4, minHeight: 200 }}
          >
            <AnimatePresence initial={false}>
              <Card key={selectedItem.label}>
                <Heading as="h6">{selectedItem.label}</Heading>
                {selectedItem.value}
              </Card>
            </AnimatePresence>
          </Box>
        </GridRow.Col>
      </GridRow>
    </Box>
  );
}
