import React from "react";
import { motion } from "framer-motion";

import Box from "../../commons/Box";

const cardStyle = {
  borderRadius: "card",
  background: "white",
  boxShadow: "card",
  p: [3, 3, 4],
};

export default function Card({
  index,
  length,
  scrollYProgress,
  children,
  ...otherProps
}) {
  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 0 }}
        animate={{ opacity: 1, y: 0, transition: { delay: 0.05 } }}
        exit={{ opacity: 0, scale: 1.2, y: -10 }}
        transition={{
          opacity: { duration: 0.4 },
        }}
        style={{ position: "absolute", zIndex: 2, width: "100%" }}
        {...otherProps}
      >
        <Box
          sx={{
            position: "relative",
            zIndex: 1,

            ...cardStyle,
          }}
        >
          {children}
        </Box>
      </motion.div>
      <motion.div
        initial={{ y: 40, opacity: 0.1, scale: 0.85 }}
        animate={{
          y: 30,
          opacity: 0.6,
          scale: 0.9,
          transition: { delay: 0.2 },
        }}
        exit={{ y: 0, opacity: 0, scale: 1, transition: { delay: 0.05 } }}
        style={{ position: "relative", zIndex: 1, width: "100%" }}
        aria-hidden
      >
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            left: 0,
            color: "transparent",
            ...cardStyle,
          }}
        >
          {children}
        </Box>
      </motion.div>
      <motion.div
        initial={{ y: 60, opacity: 0.1, scale: 0.8 }}
        animate={{
          y: 50,
          opacity: 0.3,
          scale: 0.85,
          transition: { delay: 0.3 },
        }}
        exit={{ y: 0, opacity: 0, scale: 1, transition: { delay: 0.06 } }}
        style={{ position: "relative", zIndex: 0, width: "100%" }}
        aria-hidden
      >
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            left: 0,
            color: "transparent",
            ...cardStyle,
          }}
        >
          {children}
        </Box>
      </motion.div>
    </>
  );
}
